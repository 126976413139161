import React, { useState, useEffect, useRef } from 'react';
import AskingQuestion from '../AskingQuestion';
import { ChatContentProps } from '../../types/ChatTypes';
import { appConfig } from '../../utils/config';

const ChatContent: React.FC<ChatContentProps> = ({ messages, handleSendMessage }) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [introSuggestions, setIntroSuggestions] = useState<string[]>([]);

  useEffect(() => {
    const intros = appConfig.uiParams.sampleIntros.split('\n');
    if (intros.length < 3) {
      setIntroSuggestions(intros);
    } else {
      const shuffled = intros.sort(() => 0.5 - Math.random());
      setIntroSuggestions(shuffled.slice(0, 3));
    }
  }, [appConfig.uiParams.sampleIntros]); // Update suggestions when config changes

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const extraSpace = 22;
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight + extraSpace;
    }
  };

  const getTime = (timeStamp?: Date): string => {
    if (!timeStamp) {
      return 'No timestamp provided'; // or another suitable default string
    }
    return timeStamp.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
  };

  return (
    <div className="asq-chat-content">
      <div className="asq-chat-content-messages" ref={chatContainerRef}>
        {messages.map((message, index) => {
          // Check if the message is an alert and not the last message in the array
          const isAlertMessage = message.className?.includes('alert-');
          const isLastMessage = index === messages.length - 1;
          if (isAlertMessage && !isLastMessage) {
            return null; // Skip rendering this alert message
          }
          return (
            <div
              key={`${message.id}_${index}`}
              className={`${message.isUser ? 'asq-chat-content-user-message' : 'asq-chat-content-ai-message'} ${message.className || ''}`}
              style={{ animationDelay: message.isTyping ? '0s' : '0.5s' }}
              title={message.timestamp ? getTime(message.timestamp) : ''}
            >
              {message.isTyping ? <AskingQuestion /> : <div dangerouslySetInnerHTML={{ __html: message.text || '' }} />}
            </div>
          );
        })}
        {messages.length === 1 && introSuggestions.length > 0 && (
          <div className="asq-chat-content-suggestions">
            {introSuggestions.map((suggestion, index) =>
              suggestion.trim() ? (
                <button
                  key={`suggestion_${index + 1}`}
                  className="asq-chat-content-user-message ask-chat-content-suggest-btn"
                  onClick={() => handleSendMessage(suggestion)}
                >
                  {suggestion}
                </button>
              ) : null,
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatContent;
