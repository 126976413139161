import React, { useState, useEffect } from 'react';
import { CloseOutlined, ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
// import '../../Chatbot.css';
import { appConfig } from '../../utils/config';
import { ChatHeaderProps } from '../../types/ChatTypes';

const ChatHeader: React.FC<ChatHeaderProps> = ({ sessionState }) => {
  // Handle updating the fullscreen class and notifying the parent window
  const [isFullScreen, setFullScreen] = useState<boolean>(appConfig.uiParams.fullscreen ?? false);

  const closeChatWindow = () => {
    window.parent.postMessage('closeChatWindow', '*');
  };

  const toggleFullScreen = () => {
    setFullScreen(!isFullScreen);
  };

  useEffect(() => {
    if (isFullScreen) {
      window.parent.postMessage('fullScreenChat', '*');
    } else {
      window.parent.postMessage('windowedChat', '*');
    }
  }, [isFullScreen]);

  // Toggle fullscreen based on configuration or a change in state
  useEffect(() => {
    if (appConfig.uiParams.fullscreen !== undefined) {
      setFullScreen(appConfig.uiParams.fullscreen);
    }
  }, [appConfig.uiParams.fullscreen]);

  const indicatorColorClass = `asq-chat-header-session${sessionState}`;
  const sessionStateTitle = sessionState.charAt(0).toUpperCase() + sessionState.slice(1).replace(/-/g, ' ');
  let headerTitle = appConfig.uiParams.title;
  if (appConfig.apiParams.debugOn) headerTitle += ' (' + String(appConfig.apiParams.userId).slice(0, 8) + ')';
  return (
    <div className="asq-chat-header">
      <span className="asq-chat-header-title">
        <span className={`asq-chat-header-circle ${indicatorColorClass}`} title={sessionStateTitle}></span>
        {headerTitle}
      </span>
      <div className="asq-chat-header-buttons">
        {!appConfig.uiParams.fullscreen && (
          <Button
            className={`ask-chat-header-button ${!isFullScreen && 'hide-on-mobile'}`}
            icon={isFullScreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
            onClick={toggleFullScreen}
          />
        )}
        <Button className="ask-chat-header-button" icon={<CloseOutlined />} onClick={closeChatWindow} />
      </div>
    </div>
  );
};

export default ChatHeader;
